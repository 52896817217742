import { numberFormat } from "../utils";

export const CSS_SELECTORS = {
	INPUT: 'input',
	RESULT_BLOCK: '[data-entity-result]'
};

export class PricingCalculator
{
	constructor(el)
	{
		this.el = el;
		this.inputs = [...this.el.querySelectorAll(CSS_SELECTORS.INPUT)];
		this.resultBlock = this.el.querySelector(CSS_SELECTORS.RESULT_BLOCK);

		this.oldPrice = 0;
		this.currentPrice = 0;

		this.init();
	}

	init()
	{
		this.inputs.forEach(input => {
			input.addEventListener('change', () => this.update());
		});

		this.update();
	}

	calculate()
	{
		return this.inputs
			.filter(element => element.checked)
			.map(element => parseFloat(element.value))
			.reduce((sum, val) => sum + val, 0);
	}

	update()
	{
		this.oldPrice = this.currentPrice;
		this.currentPrice = this.calculate();

		if (this.oldPrice != this.currentPrice)
			this.renderResult();
	}
	
	renderResult()
	{
		const step = (this.currentPrice - this.oldPrice) / 30;
		let tempPrice = this.oldPrice;

		this.animation = () => {
			if (
				(this.currentPrice > this.oldPrice && tempPrice < this.currentPrice) ||
				(this.currentPrice < this.oldPrice && tempPrice > this.currentPrice)
			)
			{
				tempPrice += parseInt(step);
				this.resultBlock.innerHTML = numberFormat(tempPrice);
				requestAnimationFrame(this.animation);
			}
			else
			{
				this.resultBlock.innerHTML = numberFormat(this.currentPrice);
			}
		}

		requestAnimationFrame(this.animation);
	}
}