import barba from '@barba/core';
import anime from 'animejs/lib/anime.es.js';
import initialize from './init';
import SlidePanel from './components/SlidePanel';
import { wait } from './utils';

const rippleWrap = document.createElement('div');
rippleWrap.classList.add('ripple-wrap');

const ripple = document.createElement('div');
ripple.classList.add('ripple');

rippleWrap.appendChild(ripple);
document.body.appendChild(rippleWrap);

var mouseX = null;
var mouseY = null;
    
const onMouseUpdate = e => {
	mouseX = e.clientX;
	mouseY = e.clientY;
}

document.addEventListener('mousemove', onMouseUpdate, false);
document.addEventListener('mouseenter', onMouseUpdate, false);

export default function () {

	const startRippleAnimation = () => {

		const w = Math.max(window.innerWidth, window.innerHeight);
		const r = w + w / 4;

		return anime({
			targets: ripple,
			borderWidth: r,
			translateX: -r,
			translateY: -r,
			duration: 600,
			easing: 'easeOutQuad'
		}).finished;
	}

	const endRippleAnimation = () => {

		const w = Math.max(window.innerWidth, window.innerHeight);
		const r = (w + w / 4) * 1.5;
		
		const computedStyle = window.getComputedStyle(ripple, null);
		const left = parseInt(computedStyle.getPropertyValue('left'));
		const top = parseInt(computedStyle.getPropertyValue('top'));

		return anime({
			targets: ripple,
			width: r,
			height: r,
			translateX: -r - Math.max(left, top) / 2,
			translateY: -r - Math.max(left, top) / 2,
			duration: 600,
			easing: 'easeOutQuad'
		}).finished;
	}

	barba.init({

		timeout: 50000,

		transitions: [{
	
			priority: 2,
	
			async beforeLeave({ current })
			{
				const slidePanel = SlidePanel.get(document.querySelector('#slide-panel'));
				if (slidePanel)
					slidePanel.close();

				await (setTimeout(() => {
					//ripple.style.transform = 'translate3d(' + mouseX + 'px, ' + mouseY + 'px, 0px)';
					ripple.style.left = mouseX + 'px';
					ripple.style.top = mouseY + 'px';
				}));
	
				rippleWrap.classList.add('active');
				await startRippleAnimation();
	
				document.querySelector('html').classList.remove('smooth-scroll');
				
				current.container.style.visibility = 'hidden';
	
				return true;
			},
	
			async enter({next})
			{
				// next.container.style.opacity = '0';
				
				return true;
			},
			
			async after({next})
			{
				const waitScroll = () => new Promise((resolve, reject) => {
					
					if (window.pageYOffset !== 0)
					{
						window.addEventListener('scroll', () => resolve(), {once: true})
						window.scroll(0, 0);
					}
					else
					{
						resolve();
					}
				});
	
				const waitOpacity = () => new Promise((resolve, reject) => {
					next.container.style.opacity = '1';
					next.container.addEventListener('transitionend', resolve);
				});

				await waitScroll();

				await setTimeout(() => initialize(), 10);

				await endRippleAnimation();
				rippleWrap.classList.remove('active');
				ripple.removeAttribute('style');

				// await waitOpacity();
				await setTimeout(() => { document.querySelector('html').classList.add('smooth-scroll')});
	
				setTimeout(() => {
					var scrollY = window.location.hash.trim() !== '' && document.querySelector(window.location.hash) ? 
						document.querySelector(window.location.hash).offsetTop : 0;

					if (scrollY > 0)
						window.scrollTo(0, scrollY);
				}, 400);
				
				return true;
			}
	
		}, {
			
			priority: 1,
	
			custom({trigger})
			{
				return trigger === 'forward' || trigger === 'back';
			},
	
			async after()
			{
				initialize();
			}
		}]
	});
}