const DEFAULT_OPTIONS = {
	toggleSelector: '[data-slide-panel-toggle]',
	openSelector: '[data-slide-panel-open]',
	closeSelector: '[data-slide-panel-close]'
};

const CSS_CLASSES = {
	PANEL: 'slide-panel',
	IS_OPEN: 'slide-panel--is-open',
	OVERLAY: 'slide-panel-overlay'
};

const TOUCH_TRESHOLD = 100;

const cache = {};

export default class SlidePanel
{
	constructor(element, options) 
	{
		this.element = element;
		this.options = Object.assign(DEFAULT_OPTIONS, options);

		if (this.element)
		{
			this.init();
			cache[this.element] = this;
		}
	}

	outsideClickHandle()
	{
		this.close();
	}

	onTouchStart(e)
    {
        this.touchStartX = e.changedTouches[0].pageX;
    }

    onTouchEnd(e)
    {
		const distX = e.changedTouches[0].pageX - (this.touchStartX || 0);
		
        if (Math.abs(distX) >= TOUCH_TRESHOLD && distX > 0)
        {
           this.close();
        }
    }

	open()
	{
		this.overlay = document.createElement('div');
		this.overlay.classList.add(CSS_CLASSES.OVERLAY);

		document.body.style.overflow = 'hidden';
		document.body.appendChild(this.overlay);

		this.element.classList.add(CSS_CLASSES.IS_OPEN);

		this.overlay.addEventListener('click', this.outsideClickHandle.bind(this));
		document.addEventListener('touchstart', this.onTouchStart.bind(this));
		document.addEventListener('touchend', this.onTouchEnd.bind(this));
	}
	
	close()
	{
		this.element.classList.remove(CSS_CLASSES.IS_OPEN);
		
		if (this.overlay)
		{
			this.overlay.removeEventListener('click', this.outsideClickHandle);
			this.overlay.remove();
		}

		this.touchStartX = 0;
		document.removeEventListener('touchstart', this.onTouchStart);
		document.removeEventListener('touchend', this.onTouchEnd);

		delete document.body.style.removeProperty('overflow');
	}

	toggle()
	{
		if (this.element.classList.contains(CSS_CLASSES.IS_OPEN))
		{
			this.close();
		}
		else
		{
			this.open();
		}
	}

	init()
	{
		const toggleButtons = document.querySelectorAll(this.options.toggleSelector);
		const openButtons = document.querySelectorAll(this.options.openSelector);
		const closeButtons = document.querySelectorAll(this.options.closeSelector);

		for (let button of toggleButtons) button.addEventListener('click', e => { e.preventDefault(); this.toggle(); });
		for (let button of openButtons) button.addEventListener('click', e => { e.preventDefault(); this.open(); });
		for (let button of closeButtons) button.addEventListener('click', e => { e.preventDefault(); this.close(); });
	}

	static get(el)
	{
		return cache[el];
	}
}