export const DEFAULT_CONFIG = {
	radius: 40
};
export const DEFAULT_DATA_CONFIG = {
	max: 10
};
export const CLEAR_TIMEOUT_MS = 2000;
export const POINT_VALUE = 3;
export const BREAKPOINT = 998;

const addPoints = new WeakMap();
const eventHandlers = new WeakMap();

export default class 
{
	constructor(el, config = {}, dataConfig = {})
	{
		this.el = el;
		this.config = {...DEFAULT_CONFIG, config};
		this.dataConfig = {...DEFAULT_DATA_CONFIG, dataConfig}
		this.waiting = false;
		this.frame = null;

		addPoints.set(this, new Set());

		if (window.innerWidth >= BREAKPOINT)
			this.init();

		window.addEventListener('resize', () => {
			if (window.innerWidth >= BREAKPOINT)
			{
				this.destroy();
				this.init();
			}
			else
				this.destroy();
		});
	}

	init()
	{
		this.heatmapInstance = h337.create({
			container: this.el,
			...this.config
		});

		this.heatmapInstance.setData({
			data: [],
			...this.dataConfig
		});

		eventHandlers.set(this, ({ pageX, pageY }) => {
			if (this.waiting)
				return true;

			this.drawPoint(
				pageX - this.el.offsetLeft,
				pageY - this.el.offsetTop
			);

			clearTimeout(this.clearIdle);
			this.clearIdle = setTimeout(() => this.clear(), CLEAR_TIMEOUT_MS);
		});
		
		this.el.addEventListener('mousemove', eventHandlers.get(this));
	}

	drawPoint(x, y)
	{
		this.frame = this.frame || requestAnimationFrame(() => {
			this.heatmapInstance.addData({
				x,
				y,
				value: POINT_VALUE
			});

			this.frame = null;
		});
	}

	clear()
	{
		const clearData = this.heatmapInstance.getData().data || [];

		if (addPoints.get(this).size)
			addPoints.get(this).clear();

		const frame = () => {
			const data = clearData
				.map(point => {
					point.value -= 0.1;
					return point;
				})
				.filter(point => point.value >= 0);

			this.heatmapInstance.setData({
				data,
				...this.dataConfig
			});

			if (data.length)
				requestAnimationFrame(frame);
			else
				this.waiting = false;
		};

		this.waiting = true;
		requestAnimationFrame(frame);
	}

	destroy()
	{
		if (!this.heatmapInstance)
			return;

		this.el.querySelector('canvas').remove();
		this.el.removeEventListener('mousemove', eventHandlers.get(this));
		this.heatmapInstance = null;
	}
}