const CSS_CLASSES = {
	NUMBERS: 'stages__numbers',
	STAGE_NUMBER: 'stages__number',
	ACTIVE_STAGE_NUMBER: 'stages__number--active',
	LAST_STAGE_NUMBER: 'stages__number--last',
	STAGE_LIST: 'stages__list',
	STAGE: 'stages__stage',
	ACTIVE: 'stages--active'
};

export default class Stages 
{
	constructor(element)
	{
		this.element = element;

		if (this.element)
		{
			this.init();
		}
	}

	update()
	{
		if (this.numberNode)
		{
			this.numberNode.innerText = this.activeStageNumber;
			this.lastNumberNode.innerText = this.lastStageNumber;
		}
	}

	onStageHoverHandle(e)
	{
		const stageNumber = e.target.getAttribute('data-stage-number');

		this.lastStageNumber = this.activeStageNumber;
		this.activeStageNumber = stageNumber || 5;

		this.update();
	}

	onStageListEnterHandle()
	{
		this.element.classList.add(CSS_CLASSES.ACTIVE);
	}

	onStageListLeaveHandle()
	{
		this.element.classList.remove(CSS_CLASSES.ACTIVE);
	}

	init()
	{
		this.activeStageNumber =10;
		this.lastStageNumber = 0;

		this.numberNode = document.createElement('span');
		this.numberNode.classList.add(CSS_CLASSES.STAGE_NUMBER)
		this.numberNode.classList.add(CSS_CLASSES.ACTIVE_STAGE_NUMBER);

		this.lastNumberNode = document.createElement('span');
		this.lastNumberNode.classList.add(CSS_CLASSES.STAGE_NUMBER)
		this.lastNumberNode.classList.add(CSS_CLASSES.LAST_STAGE_NUMBER);

		this.numberNode.innerText = this.activeStageNumber;
		this.lastNumberNode.innerText = this.lastStageNumber;

		this.stageList = document.querySelector(`.${CSS_CLASSES.STAGE_LIST}`);
		this.numbers = document.querySelector(`.${CSS_CLASSES.NUMBERS}`);
		this.stages = document.querySelectorAll(`.${CSS_CLASSES.STAGE}`);

		if (this.numbers)
		{
			this.numbers.appendChild(this.numberNode);
			this.numbers.appendChild(this.lastNumberNode);
		}

		if (this.stageList)
		{
			this.stageList.addEventListener('mouseenter', this.onStageListEnterHandle.bind(this));
			this.stageList.addEventListener('mouseleave', this.onStageListLeaveHandle.bind(this));
		}
		
		for (let stage of this.stages)
		{
			stage.addEventListener('mouseenter', this.onStageHoverHandle.bind(this));
		}
	}
}