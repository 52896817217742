const CSS_CLASSES = {
	ELEMENT: 'md-text-field',
	LABEL: 'md-text-field__label',
	INPUT: 'md-text-field__input',
	IS_READY: 'is-ready',
	IS_FOCUSED: 'is-focused',
	IS_FILLED: 'is-filled',
	IS_DISABLED: 'is-disabled',
	IS_INVALID: 'is-invalid',
};


export default class MaterialTextField
{
	constructor(element)
	{
		this.element = element;

		if (this.element)
		{
			this.init();
		}
	}

	checkDisabled()
	{
		if (this.input.disabled)
		{
			this.element.classList.add(CSS_CLASSES.IS_DISABLED);
		}
		else
		{
			this.element.classList.remove(CSS_CLASSES.IS_DISABLED);
		}
	}

	checkValidity()
	{
		if (this.input.validity)
		{
			if (!this.input.validity.valid)
			{
				this.element.classList.add(CSS_CLASSES.IS_INVALID);
			}
			else
			{
				this.element.classList.remove(CSS_CLASSES.IS_INVALID);
			}
		}
	}

	checkFilled()
	{
		if ((this.input.value || '').length || this.input.placeholder.trim() !== '')
		{
			this.element.classList.add(CSS_CLASSES.IS_FILLED);
		}
		else
		{
			this.element.classList.remove(CSS_CLASSES.IS_FILLED);
		}
	}

	checkFocus()
	{
		if (document.activeElement === this.input)
		{
			this.element.classList.add(CSS_CLASSES.IS_FOCUSED);
		}
		else
		{
			this.element.classList.remove(CSS_CLASSES.IS_FOCUSED);
		}
	}

	updateClasses()
	{
		this.checkDisabled();
		this.checkValidity();
		this.checkFilled();
		this.checkFocus();
	}

	onInputHandle()
	{
		this.updateClasses();
	}

	onFocusHandle()
	{
		this.element.classList.add(CSS_CLASSES.IS_FOCUSED);
	}
	
	onBlurHandle()
	{
		this.element.classList.remove(CSS_CLASSES.IS_FOCUSED);
	}

	bindInputEvents()
	{
		if (!this.input)
		{
			return; 
		}

		this.input.addEventListener('input', this.onInputHandle.bind(this));
		this.input.closest('form').addEventListener('reset', () => { setTimeout(() => { this.updateClasses(); }) });
		this.input.addEventListener('focus', this.onFocusHandle.bind(this));
		this.input.addEventListener('blur', this.onBlurHandle.bind(this));
	}

	init()
	{
		this.label = this.element.querySelector(`.${CSS_CLASSES.LABEL}`);
		this.input = this.element.querySelector(`.${CSS_CLASSES.INPUT}`);

		if (this.input)
		{
			this.bindInputEvents();
			this.updateClasses();

			this.element.classList.add('is-ready');
		}
	}
}