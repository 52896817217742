import './plugins/LeaderLine';
import { wait } from './utils';

const lines = new Set();

const lineAnimationOptions = {
	duration: 400,
	timing: 'linear'
};

const lineOptions = {
	dash: {len: 8, gap: 4, animation: true},
	endPlug: 'behind',
	color: '#B4B4B4',
	size: 1,
	hide: true
};

export function clearLines()
{
	if (lines.size)
	{
		lines.forEach(line => line.remove());
		lines.clear();
	}
}

export function drawLines()
{
	const node = document.querySelector('.better-stages');

	if (!node)
		return;

	const points = node.querySelectorAll('[data-stages-point]');

	// [...node.querySelectorAll('.better-stages__stage')].forEach(node => {
	// 	node.addEventListener('mouseenter', (e) => {
	// 		const index = [...e.target.parentNode.children].indexOf(e.target) ;
	// 		const line = [...lines][index];
	// 		line.setOptions({
	// 			color: '#FF3366',
	// 			dropShadow: true
	// 		});
	// 	});

	// 	node.addEventListener('mouseleave', (e) => {
	// 		const index = [...e.target.parentNode.children].indexOf(e.target) ;
	// 		const line = [...lines][index];
	// 		line.setOptions({
	// 			color: '#B4B4B4',
	// 			dropShadow: false
	// 		});
	// 	});
	// })

	// 1 => 2
	lines.add(
		new LeaderLine(
			points[0],
			LeaderLine.pointAnchor(points[1], {x: -2, y: 2}),
			{
			...lineOptions,
				//startSocketGravity: [300, -50],
				endSocket: 'top',
				startSocketGravity: [window.outerWidth * 15.625 / 100, -window.outerWidth * 2.6 / 100],
				endSocketGravity: [0, 15],
			})
	);

	// 2 => 3
	lines.add(
		new LeaderLine(
			LeaderLine.pointAnchor(points[1], {x: 14, y: 10}),
			LeaderLine.pointAnchor(points[2], {x: 0, y: 2}),
			{
				...lineOptions,
				// startSocketGravity: [400, 100],
				// endSocketGravity: [-230, -50],
				startSocketGravity: [window.outerWidth * 20.83 / 100, window.outerWidth * 5.21 / 100],
				endSocketGravity: [-window.outerWidth * 11.98 / 100, -window.outerWidth * 2.6 / 100],
			}
		)
	);

	// 3 => 4
	if (window.outerWidth >= 1366)
	{
		lines.add(
			new LeaderLine(
				LeaderLine.pointAnchor(points[2], {x: 14, y: 10}),
				LeaderLine.pointAnchor(points[3], {x: 14, y: 8}),
				{
					...lineOptions,
					endSocket: 'right',
					// startSocketGravity: [300, 100],
					// endSocketGravity: [250, 50],
					startSocketGravity: [window.outerWidth * 15.63 / 100, window.outerWidth * 5.21 / 100],
					endSocketGravity: [window.outerWidth * 13.02 / 100, window.outerWidth * 2.6 / 100],
				}
			)
		);
	}
	else
	{
		lines.add(
			new LeaderLine(
				LeaderLine.pointAnchor(points[2], {x: 14, y: 10}),
				LeaderLine.pointAnchor(points[3], {x: 14, y: 8}),
				{
					...lineOptions,
					endSocket: 'right',
					// startSocketGravity: [200, 100],
					// endSocketGravity: [250, 50],
					startSocketGravity: [window.outerWidth * 10.42 / 100, window.outerWidth * 5.21 / 100],
					endSocketGravity: [window.outerWidth * 13.02 / 100, window.outerWidth * 2.6 / 100],
				}
			)
		);
	}

	// 4 => 5
	lines.add(
		new LeaderLine(
			LeaderLine.pointAnchor(points[3], { x: -2, y: 4}),
			LeaderLine.pointAnchor(points[4], { x: 14, y: 2}),
			{
				...lineOptions,
				startSocket: 'left',
				endSocket: 'right',
				// startSocketGravity: [-250, -50],
				// endSocketGravity: [200, -70]
				startSocketGravity: [-window.outerWidth * 13.02 / 100, -window.outerWidth * 2.6 / 100],
				endSocketGravity: [window.outerWidth * 10.42 / 100, -window.outerWidth * 3.65 / 100]
			}
		)
	);

	// 5 => 6
	lines.add(
		new LeaderLine(
			LeaderLine.pointAnchor(points[4], {x: -2, y: 10}),
			LeaderLine.pointAnchor(points[5], {x: 12, y: 0}),
			{
				...lineOptions,
				endSocket: 'top',
				// startSocketGravity: [-200, 75],
				// endSocketGravity: [70, -140]
				startSocketGravity: [-window.outerWidth * 10.42 / 100, window.outerWidth * 3.91 / 100],
				endSocketGravity: [window.outerWidth * 3.65 / 100, -window.outerWidth * 7.29 / 100]
			}
		)
	);

	// 6 => 7
	lines.add(
		new LeaderLine(
			LeaderLine.pointAnchor(points[5], {x: 4, y: 12}),
			LeaderLine.pointAnchor(points[6], {x: 4, y: 0}),
			{
				...lineOptions,
				endSocketGravity: [-window.outerWidth * 1.3 / 100, -window.outerWidth * 2.6 / 100],
				startSocketGravity: [-window.outerWidth * 5.21 / 100, window.outerWidth * 7.55 / 100]
			}
		)
	);

	// 7 => 8
	lines.add(
		new LeaderLine(
			LeaderLine.pointAnchor(points[6], {x: 12, y: 12}),
			LeaderLine.pointAnchor(points[7], {x: 0, y: 8}),
			{
				...lineOptions,
				endSocket: 'left',
				startSocketGravity: [window.outerWidth * 7.81 / 100, window.outerWidth * 9.11 / 100],
				endSocketGravity: [0, 0]
			}
		)
	);

	// 8 => final
	const nodeRect = node.getBoundingClientRect();
	const line = new LeaderLine(
		LeaderLine.pointAnchor(points[7], {x: 12, y: 4}),
		LeaderLine.pointAnchor(node, {x: window.outerWidth - window.outerWidth * 10.42 / 100, y: nodeRect.height / 3 * 2 }),
		{
			...lineOptions,
			// startSocketGravity: [500, -300],
    		// endSocketGravity: [-500, 500]
			startSocketGravity: [window.outerWidth * 26.04 / 100, -window.outerWidth * 15.63 / 100],
			endSocketGravity: [-window.outerWidth * 26.04 / 100, window.outerWidth * 26.04 / 100]
		}
	)
	lines.add(line);

	const linesPromiseReducer = (promise, line) => {
		return promise.then(() => {
			if (!lines.has(line))
				return Promise.resolve();

			line.show('draw', lineAnimationOptions);
			return wait(lineAnimationOptions.duration);
		});
	};

	[...lines].reduce(linesPromiseReducer, wait(100));
}