
import MaterialTextField from './components/MaterialTextField';
import SlidePanel from './components/SlidePanel';
import Waves from './plugins/waves';
import AOS from './plugins/aos';
import Rellax from './plugins/rellax';
import tippy from 'tippy.js';
import Heatmap from './components/Heatmap';

import PNotify from './plugins/PNotify';
import PNotifyStyleMaterial from './plugins/PNotifyStyleMaterial.js';
import PNotifyButtons from './plugins/PNotifyButtons';

import { drawLines, clearLines } from './betterstages';
import { wait, debounce } from './utils';
import { PricingCalculator } from './components/PricingCalculator';
import Stages from './components/Stages';

PNotify.defaults.styling = 'material';

export default function init(context = document)
{
	// initialize better stages
	if (context.querySelector('.better-stages'))
	{
		// align titles
		const alignTitles = async () => {
			
			const dataItems = document.querySelectorAll('.better-stages__data');
			if (dataItems.length)
			{
				[...dataItems].forEach(el => {
					const title = el.querySelector('.better-stages__title');
					const note = el.querySelector('.better-stages__tooltip');
			
					title.style.width = 'auto';
					note.classList.add('d-none');
				});

				await wait(0);

				[...dataItems].forEach(el => {
					const title = el.querySelector('.better-stages__title');
					const note = el.querySelector('.better-stages__tooltip');
					const span = title.querySelector('span');
			
					title.style.width = span.offsetWidth + 5 + 'px';
					note.classList.remove('d-none');
				});
			}
		}

		alignTitles();

		// animation on scroll events
		document.addEventListener('aos:out', ({ detail }) => {
			const el = detail.detail || detail;
			if (el.getAttribute('data-aos-id') === 'better-stages')
				clearLines();
		});

		document.addEventListener('aos:in:better-stages', debounce(async ({ detail }) => {
			const el = detail.detail || detail;

			if (el.classList.contains('aos-animate'))
			{
				await wait(el.getAttribute('data-aos-duration'));
		
				if (window.innerWidth >= 992)
				{
					clearLines();
					drawLines();
				}
			}
		}, 500));

		// redraw on resize
		window.addEventListener('resize', debounce(() => {
			alignTitles();
			clearLines();
			if (window.innerWidth >= 992)
				drawLines();
		}, 200));
	}

	// Initialize animation on scroll
	AOS.init();

	// Initialize text fields
	const textFields = context.querySelectorAll('.md-text-field');
	for (let element of textFields)
		new MaterialTextField(element);

	// initialize tooltips
	tippy('.tooltip', {
		theme: 'light'
	})

	// Initialize ripple effect
	Waves.init({ duration: 500, delay: 200 });
	Waves.attach('.md-button--waves', 'waves-dark');

	// initialize Slide panel on mobile
	new SlidePanel(context.querySelector('#slide-panel'));

	// initialize parallax effect
	if (context.querySelector('.rellax'))
		new Rellax('.rellax');

	// initialize yandex share 
	if ((Ya || {}).share2 && context.querySelector('#yaShare2'))
	{
		const share = Ya.share2('yaShare2', {
			url: location.href
		});
	}

	// 
	const stages = document.querySelector('.stages');
	if (stages)
		new Stages(stages)

	// initialize pricing calculator
	const calcElement = context.querySelector('#calculator');
	if(calcElement)
		new PricingCalculator(calcElement);

	// initialize recall form
	const forms = document.querySelectorAll('form[action^="/recall-request"]');
	[...forms].forEach(form => {
		form.addEventListener('submit', async event => {
			event.preventDefault();

			if (window.yaCounter56301220)
				yaCounter56301220.reachGoal('callback');

			const formData = new FormData(form);
			
			try
			{
				const response = await fetch(form.action, {
					method: 'POST',
					body: formData
				});

				const result = await response.json();

				if (result.success)
				{
					PNotify.alert({
						text: result.message,
						type: 'success'
					});

					form.reset();
				}
				else
				{
					PNotify.alert({
						text: result.message,
						type: 'error'
					});
				}
			}
			catch(e)
			{
				console.error(e);
			}
		});
	});

	// intro
	const intro = context.querySelector('.intro');
	if (intro)
	{
		const playBtn = intro.querySelector('.intro__play');
		const video = intro.querySelector('.intro__video');

		video.addEventListener('click', async () => {
			playBtn.style.display = 'none';
			await video.play();
			video.controls = true;
		});

		// video.addEventListener('pause', () => {
		// 	video.controls = false;
		// 	playBtn.style.display = 'block';
		// });
	}

	// heatmap
	const heatmapNodes = document.querySelectorAll('.section-heatmap');
	[...heatmapNodes].forEach(element => new Heatmap(element))

	// canvas

	// let frame;

	// const el = document.querySelector('.section--index');
	// const heatmapInstance = h337.create({
	// 	container: el,
	// 	radius: 40,
	// 	// gradient: {
	// 	// 	'.1': '#ffffff',
	// 	// 	'.75': '#ff3366'
	// 	// }
	// });

	// heatmapInstance.setData({ max: 10, data: []});

	// let t = null;
	// let stopped = false;

	// const clear = () => {

	// 	const clearReadyData = heatmapInstance.getData().data

	// 	const draw = () => {
	// 		const data = clearReadyData
	// 			.map(point => { if (point > 10) point.fresh = true; point.value -= 0.1; return point; })
	// 			.filter(point => point.value >= 0)

	// 		heatmapInstance.setData({ max: 10, data});

	// 		if (data.length)
	// 		{
	// 			requestAnimationFrame(draw);
	// 		}
	// 		else
	// 		{
	// 			stopped = null;
	// 		}
	// 	}

	// 	requestAnimationFrame(draw);
	// 	stopped = true;
	// }

	// const addData = new Set();

	// const draw = () => {
	// 	heatmapInstance.addData([...addData]);
	// 	addData.clear();

	// 	frame =  null;
	// }

	// el.addEventListener('mousemove', e => {
		
	// 	if (!stopped)
	// 	{
	// 		addData.add({
	// 			x: e.pageX,
	// 			y: e.pageY,
	// 			value: 2.5
	// 		});
	// 	}

	// 	frame = frame || window.requestAnimationFrame(draw);

	// 	clearTimeout(t)
	// 	t = setTimeout(clear, 1000);
	// });

	//if (context.querySelector('#better-stages'))
		//new BetterStages();

	// ����������
	// const forms = context.querySelectorAll('form');
	// for (let form of forms)
	// {
	// 	new RecallForm(form, {
	// 		onSubmit() 
	// 		{
	// 			yaCounter56301220.reachGoal('callback') 
	// 		} 
	// 	});
	// }
}