
export const debounce = (fn) => {
	let timeout;

	return function () {
		var context = this;
		var args = arguments;

		if (timeout) {
			window.cancelAnimationFrame(timeout);
		}

		timeout = window.requestAnimationFrame(function () {
			fn.apply(context, args);
		});
	}
}

export async function wait(timeout)
{
	return new Promise((resolve, reject) => {
		setTimeout(resolve, timeout);
	});
}

export function numberFormat(num, locale = 'ru')
{
	return new Intl.NumberFormat(locale).format(num);
}